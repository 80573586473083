import styled from "@emotion/styled";
export const StyledAssetComponent = styled.div`
  position: relative;
  border-radius: ${(props)=>props.style.borderRadius};
  overflow: hidden;

  .main-image {
    width: 100%;
    display: flex;
  }

  .main-image.round {
    border-radius: 50%;
  }

  img.with-window-mask,
  .with-window-mask > video {
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
