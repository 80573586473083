import styled from "@emotion/styled";
export const StyledVideoComponent = styled.div`
  outline: none;

  &.hide-black-bars {
    height: 32vw;

    .react-player-video-component video {
      object-fit: cover;
    }
  }
`;
